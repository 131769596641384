import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const LimbusDatabaseGachaPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="lc">
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company/">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/limbus-company/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Gacha and monetization</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/limbuscompany/categories/category_gacha.png"
            alt="Gacha and monetization"
          />
        </div>
        <div className="page-details">
          <h1>Gacha and monetization</h1>
          <h2>A guide that explains the gacha system in Limbus Company.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Video" />
        <p>
          On 17th February, Project Moon released a video with long awaited
          information about monetization and gameplay progress. We still do not
          know details about pricing and rates but we got other vital
          information.
        </p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="6KeMOUVu_HM" />
          </Col>
        </Row>
        <SectionHeader title="Gacha" />
        <p>
          <strong>“Extraction”</strong>, as Project Moon dubbed their gacha,
          banners consists of both E.G.O. and Identities.
        </p>
        <ul>
          <li>
            <strong>Identity</strong> - those are playable characters, but each
            'base' character can have multiple Identities that change the
            character's skills, passives and design,
          </li>
          <li>
            <strong>E.G.O.</strong> - this is basically an artifact that gives
            an additional skill to the Identity that has it equipped.
          </li>
        </ul>
        <p>
          While Identities follow standard, rarer stuff lower chance to get, all
          E.G.O. share the same rates and upon obtaining{' '}
          <strong>
            one E.G.O. it will be removed from the pool, meaning E.G.O. are a
            “finite” thing
          </strong>
          . If we go by Tokyo Game Show information, one roll costs 130 Lunacy,
          while a multi is 1300 Lunacy.
        </p>
        <StaticImage
          src="../../../images/limbuscompany/gacha/image7.jpg"
          alt="Gacha guide"
        />
        <p>
          When it comes to Identities,{' '}
          <strong>there is no “dupe” system</strong>. Instead, upon getting the
          same Identity, it will be converted into shards, 3/15/50 respectively.
          Those shards can be used to purchase Identities and E.G.O. as well as
          upgrade materials. Additionally, Mirror Dungeon also awards players
          with shards, more precisely, players will be able to pick a selector
          or a box with random shards.
        </p>
        <StaticImage
          src="../../../images/limbuscompany/gacha/image1.jpg"
          alt="Gacha guide"
        />
        <p>
          <strong>
            Obtained Identities start at Tier 1 and can be levelled up by
            participating in battles or using EXP tickets to increase their
            stats
          </strong>
          . Identities can be upgraded or how it is called in the video,
          “Uptied”. Upon upgrading Identity to the next Tier, it will receive
          additional passives, skills will be enhanced and speed range will
          increase. Upon a second upgrade, reaching Tier 3, new skill will be
          unlocked as well as new art for the identity. New skill will have a
          close up when used in battle.
        </p>
        <StaticImage
          src="../../../images/limbuscompany/gacha/image3.jpg"
          alt="Gacha guide"
        />
        <br></br>
        <StaticImage
          src="../../../images/limbuscompany/gacha/image5.jpg"
          alt="Gacha guide"
        />
        <br></br>
        <StaticImage
          src="../../../images/limbuscompany/gacha/image2.jpg"
          alt="Gacha guide"
        />
        <h5>Threadspinning</h5>
        <p>
          <strong>“Threadspinning”</strong> is an E.G.O. upgrade counterpart to
          Identities. Upgrading E.G.O. will unlock new passives, boost skill
          effectiveness and upon final upgrade, when E.G.O. is used in battle,
          background will be replaced with the E.G.O. one.
        </p>
        <StaticImage
          src="../../../images/limbuscompany/gacha/image8.jpg"
          alt="Gacha guide"
        />
        <SectionHeader title="Battle Pass" />
        <p>
          Limbus will be a gacha game with a battle pass which is tied to
          seasons, with goods related to the currently happening in-game events.{' '}
        </p>
        <p>
          Limbus Battle Pass follows a traditional battle pass formula of free
          and paid track, with paid offering more resources and more EGOs. While
          the free path will give access to quite a lot of EGOS, some will still
          be locked behind the paywall.
        </p>
        <StaticImage
          src="../../../images/limbuscompany/gacha/image4.jpg"
          alt="Gacha guide"
        />
        <p>
          Battle Pass will feature daily, weekly and seasonal missions.
          Completing them will reward players with Battle Pass EXP. If Battle
          Pass is completed, those missions will overflow into the Battle Pass,
          which will provide players with bonus rewards
        </p>
        <StaticImage
          src="../../../images/limbuscompany/gacha/image6.jpg"
          alt="Gacha guide"
        />
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default LimbusDatabaseGachaPage;

export const Head: React.FC = () => (
  <Seo
    title="Gacha and monetization | Limbus Company | Prydwen Institute"
    description="A guide that explains the gacha system in Limbus Company."
  />
);
